import { Cookies } from "react-cookie";

const cookies = new Cookies();

export const BANNER_COOKIE_NAME = "banner_preference";

export const setCookie = (name, token, options) => {
  return cookies.set(name, token, options);
};

export const getCookie = (token) => {
  return cookies.get(token);
};

export const removeCookie = (token) => {
  return cookies.remove(token, { sameSite: "strict", path: "/" });
};
