import { useUserInfoStore } from "../store/useUserInfoStore";
import http from "./constants/http";
import resources from "./constants/resources";
import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "@common/queryKeys";

export const getUserInfo = async () => {
  const body = {
    modes: "UserInfo",
  };

  const res = await http(resources.MEMBER).post(body);
  const resData = {
    code: res.data?.Code,
    data: res.data?.Data?.Member,
    uaddress: res.data?.Data?.Address,
    ucompany: res.data?.Data?.Company,
    person: res.data?.Data?.Person?.Rows,
    ...res.data?.Data,
  };

  return resData;
};

export const getAllUserInfo = async () => {
  const body = {
    modes: "UserInfo",
  };

  const res = await http(resources.MEMBER).post(body);

  if (res.status === 200) {
    return res.data;
  } else {
    return res;
  }
};

export const useGetUserInfo = () => {
  const updateUserInfo = useUserInfoStore((state) => state.updateUserInfo);

  return useQuery({
    queryKey: QUERY_KEYS.USERINFO.INDEX,
    queryFn: getAllUserInfo,
    onSuccess: (data) => {
      if (data?.Code === "8888") {
        alert("로그인 유지 시간이 만료 되었습니다.");
        window.open("/login", "_self");
        throw new Error("로그인 유지 시간이 만료 되었습니다.");
      } else if (data?.Code === "9999") {
        window.open("/", "_self");
        throw new Error("로그인 상태가 유효하지 않습니다.");
      } else {
        updateUserInfo(data?.Data);
        return data?.Data;
      }
    },
    onError: (error) => {
      console.error(error);
    },
  });
};
