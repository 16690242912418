export const QUERY_STATES = {
  STALE_TIME: 5 * 60 * 1000, // 5분
  CACHE_TIME: 30 * 60 * 1000, // 30분
  retry: 1, // 요청 실패 시 한 번만 재시도
};

export const QUERY_KEYS = {
  SETTING: {
    BANNERS: {
      INDEX: ["banners"],
    },
  },

  SITE_CONFING: {
    INDEX: ["siteConfig", "prototype"],
  },

  USERINFO: {
    INDEX: ["userInfoList"],
  },

  PRODUCTS: {
    BESTPRODUCTS: {
      INDEX: ["best", "products"],
      DETAIL: ["best", "products", "detail"],
    },
  },

  CUSTOMIZING: {
    //카테고리
    CATEGORY: {
      INDEX: ["itemDetail"],
      DETAIL: (id) => ["itemDetail", "detail", id],
    },
    //장바구니
    BASKETS: {
      INDEX: ["customBasketList"],
    },
    GALLERY: {
      INDEX: ["customGalleryList"],
    },

    //판촉물
    PROMOTION: {
      INDEX: ["promotionList"],
      DETAIL: (id) => ["promotionList", "detail", id],
    },
    //시제품
    PROTOTYPE: {
      INDEX: ["prototypeList"],
      DETAIL: (id) => ["prototypeList", "detail", id],
    },
    //소량인쇄
    PRINT: {
      FAQ: ["faq"],
    },
  },
};
